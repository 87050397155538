import classes from "./ContentProviders.module.css"
import { getContentProviders } from "@fastpoint/mediaspot-management"
import { useEffect, useState } from "react"
import { ContentProviderEntity } from "@fastpoint/mediaspot-management/dist/entities/ContentProviderEntity"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons"
import CreateContentProviderModal from "./CreateContentProviderModal/CreateContentProviderModal"

const ContentProviders = () => {

    const [cps, setCps] = useState<ContentProviderEntity[]>([])
    const [isCreatingContentProvider, setCreatingContentProvider] = useState<boolean>(false)

    const loadContentProviders = async() => {
        
        const contentProvidersResponse = await getContentProviders()
        if(!contentProvidersResponse.status){
            alert("error during content providers loading")
            return
        }
        setCps(contentProvidersResponse.data as ContentProviderEntity[])
        
    }

    useEffect(() => {
        loadContentProviders()
    }, [])

    const deleteContentProvider = async() => {
        alert("Content provider deletion is not implemented yet")
    }

    const onDeleteContentProviderClicked = async(contentProvider: ContentProviderEntity) => {
        if(window.confirm(`Are you sure you want to delete content provider : "${contentProvider.name}" ?`)){
            await deleteContentProvider()
        }
    }

    return (
        <div className={classes.ContentProvidersListContainer}>
            <div className={classes.ContentProvidersListContent}>
                <h3>Content providers</h3>
                <div className={classes.ContentProvidersCreateButtonContainer}>
                    <button onClick={() => setCreatingContentProvider(true)} className={classes.ContentProvidersCreateButton}><FontAwesomeIcon icon={faPlusCircle} size="sm"/> Create content provider</button>
                </div>
                <hr />
                <table className={classes.ContentProvidersListTable}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Folder name</th>
                            <th>Custom bucket</th>
                            <th>Path in bucket</th>
                            <th>Users</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cps.map(cp => {
                                return <tr key={cp.technical_name} >
                                    <td>{cp.name}</td>
                                    <td>{cp.description}</td>
                                    <td>{cp.technical_name}</td>
                                    <td>{cp.aws_bucket_name}</td>
                                    <td>{cp.path_in_bucket}</td>
                                    <td>{cp.users.join(",")}</td>
                                    <td>
                                        <button className={classes.ContentProvidersEditButton}><FontAwesomeIcon icon={faEdit} size="sm"/> Edit</button>
                                        <button onClick={() => onDeleteContentProviderClicked(cp)} className={classes.ContentProvidersDeleteButton}><FontAwesomeIcon icon={faTrash} size="sm"/> Delete</button>
                                    </td>
                                    
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>


            {
                isCreatingContentProvider ? 
                    <CreateContentProviderModal 
                        onBackClicked={() => setCreatingContentProvider(false)}
                        onContentProviderCreated={() => setCreatingContentProvider(false)}
                    /> 
                : null 
            }
        </div>
    )
}

export default ContentProviders