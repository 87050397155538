import {ChangeEvent, useState} from "react";
import {Trans} from "react-i18next";
import classes from "./Settings.module.css"

const SHOW_NO_VISIBLE_NODE_KEY = "show_not_visible_nodes"
const SHOW_STORE_NODE_KEY = "show_store_nodes"
const SHOW_ARCHIVE_DEVICES = "show_archive_devices"

const getLocalStorageBooleanValue = (key: string): boolean => {
    const value = localStorage.getItem(key)
    if(value === null || (value !== "true" && value !== "false")){
        return false
    }else{
        return value === "true"
    }
}

const shouldShowNoVisibleNodes = () => {
    return getLocalStorageBooleanValue(SHOW_NO_VISIBLE_NODE_KEY)
}

const shouldShowStoreNodes = () => {
    return getLocalStorageBooleanValue(SHOW_STORE_NODE_KEY)
}

const shouldShowArchiveDevices = () => {
    return getLocalStorageBooleanValue(SHOW_ARCHIVE_DEVICES)
}


const Settings = () => {


    const [showNotVisibleNodes, setShowNotVisibleNodes] = useState<boolean>(shouldShowNoVisibleNodes())
    const [showStoreNodes, setShowStoreNodes] = useState<boolean>(shouldShowStoreNodes())
    const [showArchiveDevices, setShowArchiveDevices] = useState<boolean>(shouldShowArchiveDevices())


    const onVisibleAreaOptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const lastValue: boolean = showNotVisibleNodes
        localStorage.setItem(SHOW_NO_VISIBLE_NODE_KEY,  (!lastValue ? "true" : "false"))
        setShowNotVisibleNodes(!lastValue)
    }

    const onStoreNodeVisibilityOptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const lastValue: boolean = showStoreNodes
        localStorage.setItem(SHOW_STORE_NODE_KEY, (!lastValue ? "true" : "false"))
        setShowStoreNodes(!lastValue)
    }

    const onArchiveDevicesVisibilityOptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const lastValue: boolean = showArchiveDevices
        localStorage.setItem(SHOW_ARCHIVE_DEVICES, (!lastValue ? "true" : "false"))
        setShowArchiveDevices(!lastValue)
    }


    return (
        <div className={classes.SettingsContainer}>
            <label className={classes.SettingsTitle}><Trans>Settings</Trans></label>

            <div className={classes.SettingsItem}>
                <input id={"hideVisibleNodesSettingsCheckbox"} onChange={onVisibleAreaOptionChanged} type="checkbox" name="associated" checked={showNotVisibleNodes}/>
                <label htmlFor={"hideVisibleNodesSettingsCheckbox"}> <Trans>ShowNotVisibleAreas</Trans></label>
            </div>

            <div className={classes.SettingsItem}>
                <input id={"showStoreNodesSettingsCheckbox"} onChange={onStoreNodeVisibilityOptionChanged} type="checkbox" name="associated" checked={showStoreNodes}/>
                <label htmlFor={"showStoreNodesSettingsCheckbox"}> <Trans>ShowStoreAreas</Trans></label>
            </div>

            <div className={classes.SettingsItem}>
                <input id={"showArchiveDevicesSettingsCheckbox"} onChange={onArchiveDevicesVisibilityOptionChanged} type="checkbox" name="associated" checked={showArchiveDevices}/>
                <label htmlFor={"showArchiveDevicesSettingsCheckbox"}> <Trans>ShowArchiveDevices</Trans></label>
            </div>
        </div>
    )
}

export default Settings