import { addContentProvider, getContentProviders, refreshDevice} from "@fastpoint/mediaspot-management"
import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import classes from "./AddContentProviderModal.module.css"
import { ContentProviderEntity } from "@fastpoint/mediaspot-management/dist/entities/ContentProviderEntity"

interface IProps {
    deviceId: string
    onBackClicked: () => void
    onContentProviderAdded: () => void
}

const AddContentProviderModal = (props: IProps) => {

    const [cps, setCps] = useState<ContentProviderEntity[]>([])
    const [isLoadingCps, setLoadingCps] = useState<boolean>(true)
    const [isAddContentRequestInProgress, setAddContentRequestInProgress] = useState<boolean>(false)
    const [selectedContentProvider, setSelectedContentProvider] = useState<string|undefined>(undefined)

    const loadContentProviders = async() => {
        
        const contentProvidersResponse = await getContentProviders()
        setLoadingCps(false)
        if(!contentProvidersResponse.status){
            alert("error during content providers loading")
            return
        }
        setCps(contentProvidersResponse.data as ContentProviderEntity[])
        setSelectedContentProvider(contentProvidersResponse.data![0].technical_name)
    }

    const addContentProviderToMediaspot = async() => {
        setAddContentRequestInProgress(true)
        if(selectedContentProvider){

            const contentProvidersResponse = await addContentProvider(props.deviceId, selectedContentProvider)
            await refreshDevice(props.deviceId)

            setAddContentRequestInProgress(false)
            
            if(!contentProvidersResponse.status){
                alert("An error occurred, unable to add content provider to this device")
                return
            }
            
            props.onContentProviderAdded()

        }
    }

    

    useEffect(() => {
        loadContentProviders()
    }, [])


    const onAddContentProviderClicked = async(e: FormEvent) => {
        e.preventDefault()
        await addContentProviderToMediaspot()
    }

    const onSelectedContentProviderChanged = (e: ChangeEvent<HTMLSelectElement>) => {
        console.log(e.target.value)
        setSelectedContentProvider(e.target.value)
    }



    return <div className={classes.AddContentProviderContainer}>
        <div className={classes.AddContentProviderContent}>
            <label className={classes.AddContentProviderTitleLabel}>Add content provider</label>
            <hr/>
            <form className={classes.AddContentProviderForm} onSubmit={onAddContentProviderClicked}>
                {
                    isLoadingCps === true ?
                        <Loader type="Oval" color="#2F9BD9" height={30} width={100} />
                        : <select onChange={onSelectedContentProviderChanged}  value={selectedContentProvider} >
                            {
                                cps.map(cp => {
                                    return (
                                        <option key={cp.id} value={cp.technical_name}>{cp.name}</option>
                                    )
                                })
                            }
                        </select>
                }
                <div className={classes.AddContentProviderActionsButtonContainer}>
                    <button onClick={props.onBackClicked} className={classes.AddContentProviderCancelButton}>Cancel</button>
                    <button disabled={isAddContentRequestInProgress} type="submit" className={classes.AddContentProviderAddButton}>{isAddContentRequestInProgress ? <Loader type="Oval" color="#FFFFFF" height={15} width={100} />: 'Add'}</button>
                </div>
            </form>
        </div>
    </div>
}

export default AddContentProviderModal