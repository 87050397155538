import { AdvancedMediaspotParameter, ContentProviders, Firewall, getMediaspot, WifiInterfaces, deleteTasks, WelcomePage, TasksManager, WebsocketSubscriptionType, refreshDevice, CustomJSONComponent } from "@fastpoint/mediaspot-management";
import { FirewallInformation } from "@fastpoint/mediaspot-management/dist/entities/FirewallInformation";
import { Mediaspot } from "@fastpoint/mediaspot-management/dist/entities/Mediaspot";
import { Task } from "@fastpoint/mediaspot-management/dist/entities/Task";
import { TasksListener } from "@fastpoint/mediaspot-management/dist/helpers/TasksManager";
import { faSyncAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

import {Tab, Tabs, TabList, TabPanel} from "react-tabs"
import "react-tabs/style/react-tabs.css"
import ReactTooltip from "react-tooltip";
import { useToasts } from 'react-toast-notifications';
import { getReadablePendingTaskDescription, getReadableTaskDescription } from "../../../helpers/mediaspotHelper";
import classes from "./MediaspotDetails.module.css"
import Loader from "react-loader-spinner";

interface IProps {
    mediaspotId: string,
    onAddContentProviderClicked: (deviceId: string) => void,
    onMediaspotUpdate: (mediaspot: Mediaspot) => void
}



export const MediaspotDetails = (props: IProps) => {

    const { addToast } = useToasts();
    
    const [isLoading, setLoading] = useState<boolean>(true)
    const [mediaspot, setMediaspot] = useState<Mediaspot>()
    
    const [isRefreshRequestInProgress, setRefreshRequestInProgress] = useState<boolean>(false)
    

    const [tasks, setTasks] = useState<Task[]>(TasksManager.getInstance().getTasks(props.mediaspotId))

    const [tasksListener, setTasksListener] = useState<TasksListener>()

    // isMediaspotUpdate indicates if mediapsot has to be reloaded after an update or just to load info first time
    const loadMediaspot = async(isMediaspotUpdate: boolean = false) => {
        const mediaspotResponse = await getMediaspot(props.mediaspotId)
        if(!mediaspotResponse.status){
            alert("Error during mediaspot infos loading")
            return
        }

        setLoading(false)
        setMediaspot(mediaspotResponse.result)

        if(isMediaspotUpdate){
            props.onMediaspotUpdate(mediaspotResponse.result!)
        }
    }

    useEffect(() => {

        console.log("create new tasks listener")
        const _tasksListener = new (class FPTaskListener implements TasksListener {
            onTasksUpdated(tasks: Task[]): void {
                console.log("onTasksUpdated")
    
                const mediaspotTasks = TasksManager.getInstance().getTasks(props.mediaspotId)
                // Reload mediaspot once tasks completed
                if(mediaspotTasks.length === 0){
                    loadMediaspot(true)
                }
            }
            onTaskAdded(task: Task): void {
                console.log("onTaskAdded")
                
                setTasks(TasksManager.getInstance().getTasks(props.mediaspotId))
                
                const parameterValues = task.parameterValues
                    if(parameterValues && parameterValues.length > 0 && task.name === "setParameterValues"){
                        const parametersValueTask = parameterValues[0]
                        if(parametersValueTask.length >= 2){
                            const key = parametersValueTask[0]
                            const value = parametersValueTask[1]
                            addToast(`${getReadablePendingTaskDescription(key, value)}`, {
                                appearance: 'warning',
                                autoDismiss: true
                            })
                        }
                    }
                
                
            }
            onTaskDeleted(task: Task, completed: boolean): void {
                setTasks(TasksManager.getInstance().getTasks(props.mediaspotId))
    
                // Reload mediaspot once tasks completed
                const mediaspotTasks = TasksManager.getInstance().getTasks(props.mediaspotId)
                if(mediaspotTasks.length === 0){
                    loadMediaspot(true)
                }
                
                console.log("onTaskDeleted")
                if(completed){
                    const parameterValues = task.parameterValues
                    if(parameterValues && parameterValues.length > 0 && task.name === "setParameterValues"){
                        const parametersValueTask = parameterValues[0]
                        if(parametersValueTask.length >= 2){
                            const key = parametersValueTask[0]
                            const value = parametersValueTask[1]
                            const text = getReadableTaskDescription(key, value)
                            addToast(text, {
                                appearance: 'success',
                                autoDismiss: true
                            })
                        }
                    }
                    
                }else {
                    const parameterValues = task.parameterValues
                    if(parameterValues && parameterValues.length > 0 && task.name === "setParameterValues"){
                        const parametersValueTask = parameterValues[0]
                        if(parametersValueTask.length >= 2){
                            const key = parametersValueTask[0]
                            const value = parametersValueTask[1]
                            
                            addToast(`${getReadablePendingTaskDescription(key, value)} annulée`, {
                                appearance: 'warning',
                                autoDismiss: true
                            })
                        }
                    }    
                }
            }
        })()

        if(tasksListener)
            TasksManager.getInstance().unsusbcribe(WebsocketSubscriptionType.tr069_tasks, tasksListener)

        setTasksListener(_tasksListener)    
    }, [props.mediaspotId])

    useEffect(() => {
        console.log(tasksListener)
        if(tasksListener)
            TasksManager.getInstance().susbcribe(WebsocketSubscriptionType.tr069_tasks, tasksListener)
    }, [tasksListener])


    // ReactTooltip has to be rebuilt each time rendering is modified
    useEffect(() => {
        ReactTooltip.rebuild()
    })

    // Reload mediaspot and tasks when mediaspot id changed
    useEffect(() => {
        loadMediaspot()
        setTasks(TasksManager.getInstance().getTasks(props.mediaspotId))
    }, [props.mediaspotId])

    

    if(isLoading){
        return <label>Loading mediaspot infos...</label>
    }

    if(!mediaspot){
        return <label>Cannot get mediaspot infos</label>
    }

    const getTooltipText = () => {
        if(tasks){
            return tasks.map(task => {
                return `${task.name} ${(task.parameterValues ?? []).map(it => it[0])}`
            }).join("<br />")
        }
    }

    const onDeleteTasksClick = async() => {
        await deleteTasks(tasks.map(it => it._id))
    }


    
    const onRefreshClick = async() => {
        setRefreshRequestInProgress(true)
        await refreshDevice(props.mediaspotId)
        setRefreshRequestInProgress(false)
    }

    return (
        <>

        <h3 className={classes.MediaspotDetailsName}>{mediaspot.name} ({mediaspot.id}) </h3>
        {
            (tasks !== undefined && tasks.length > 0) ?

                <div className={classes.TasksContainer}>
                            <label data-text-color={"#000000"} data-place={"bottom"} data-class={classes.tooltip} data-border={true} data-border-color={"#c3bdb9"} data-background-color={"white"} data-multiline={true} data-tip={getTooltipText()}>{tasks.length} {tasks.length === 1 ? "task" : "tasks"}</label>
                            <button onClick={onDeleteTasksClick}><FontAwesomeIcon icon={faTrash} color={"#FFFFFF"}/></button>
                        </div> 
            : null
        }

        <button className={classes.MediaspotDetailsRefreshButton} onClick={onRefreshClick}>{isRefreshRequestInProgress ? <div className={classes.MediaspotDetailsRefreshLoader}><Loader type="Oval" color="#FFFFFF" height={15} width={15} /></div> : <FontAwesomeIcon icon={faSyncAlt}/>} Refresh parameters</button>

        <hr/>

        <Tabs>
            <TabList>
                <Tab>Content providers</Tab>
                <Tab>Firewall</Tab>
                <Tab>Welcomepage</Tab>
                <Tab>Wifi</Tab>
                <Tab>Advanced</Tab>
                <Tab>Custom JSON</Tab>
            </TabList>

            <TabPanel>
                <button className={classes.MediaspotDetailsAddContentProviderButton} onClick={() => props.onAddContentProviderClicked(props.mediaspotId)}>+ Add content provider</button>
                {
                    mediaspot.cp.length > 0 
                    ? <ContentProviders 
                    addToast={()=>{}}
                    contentProviders={mediaspot.cp}
                    deviceId={mediaspot.id} />
                    : null
                }
                
            </TabPanel>
            
            <TabPanel>
                <Firewall
                    deviceId={mediaspot.id}
                    firewallInformation={mediaspot.firewallInformation}
                    onFirewallUpdate={(firewallInfo: FirewallInformation)=> {}}
                    addToast={()=> {}}/>
            </TabPanel>

            <TabPanel>
                <WelcomePage
                    addToast={() => {}}
                    deviceId={mediaspot.id}
                    welcomePageTreeContents={mediaspot.welcomepageFolderContent} />
            </TabPanel>

            <TabPanel>
                <WifiInterfaces
                    addToast={() => {}}
                    deviceId={mediaspot.id}
                    interfaces={mediaspot.wifiInterfaces}
                    onInterfaceUpdate={(wifiInterfaces) => {console.log(wifiInterfaces)}} />
            </TabPanel>
            
            <TabPanel>
                <AdvancedMediaspotParameter
                    addToast={() => {}}
                    deviceId={mediaspot.id}
                    firmwareUpgradeUrl={mediaspot.firmwareUpgradeUrl}
                    mediaspotName={mediaspot.name}
                    syncserverWebsocketHost={mediaspot.syncserverWebsocketHost}
                    tr069Host={mediaspot.tr069Host}
                    mediacenterHost={mediaspot.mediacenterHost}/>

            </TabPanel>

            <TabPanel>
                <CustomJSONComponent
                    addToast={() => {}}
                    customJSON={mediaspot.customJSON}
                    deviceId={mediaspot.id}
                    onCustomJSONUpdated={() => {}}
                    />
            </TabPanel>


        </Tabs>
        <ReactTooltip/>    
        </>
        
    )
    
}