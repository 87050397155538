import React, {Component} from "react";
import {
    getConfirm,
    getToken,
    loggedIn,
    logout,
    refreshToken,
    willTokenExpire,
    isTokenExpired
} from "../api/Auth";

import {Redirect} from 'react-router-dom'

interface WithAuthProps {
    token: string|null
}

/* A higher order component is frequently written as a function that returns a class. */
export const withAuth = (AuthComponent:React.Component<WithAuthProps> | React.FC<WithAuthProps>) => {

    return class AuthWrapped extends Component<WithAuthProps> {
        state = {
            confirm: null,
            loaded: false,
            refreshTokenFunctionId: null
        };

        /* In the componentDidMount, we would want to do a couple of important tasks in order to verify the current users authentication status
        prior to granting them enterance into the app. */
        componentDidMount() {
            if (!loggedIn()) {
                this.setState({ loaded: true })
            } else {
                /* Try to get confirmation message from the Auth helper. */
                try {
                    const confirm = getConfirm();
                    this.setState({
                        confirm: confirm,
                        loaded: true
                    });
                    // const intervalId = setInterval(() => this.refreshTokenIfNeeded(), 60000)
                    const intervalId = setInterval(() => this.refreshTokenIfNeeded(), 10000)
                    this.setState({ refreshTokenFunctionId : intervalId })
                } catch (err:any) {
                    /* Oh snap! Looks like there's an error so we'll print it out and log the user out for security reasons. */
                    console.log(err);
                    logout();
                    this.setState({ loaded: true })
                }
            }
        }

        componentDidUpdate(prevProps:any, prevState:any, snapshot:any) {
            
            if(prevState.confirm !== getToken() && !isTokenExpired(getToken()!)){
                this.setState({confirm: getToken()})
            }
            
            if(prevProps.token !== this.props.token){
                this.setState({confirm: this.props.token})
            }

        }

        async refreshTokenIfNeeded(){
            if(willTokenExpire()){
                const lastUserClick = localStorage.getItem("last_user_click_timestamp")
                if(lastUserClick !== null && !isNaN(parseInt(lastUserClick))){
                    const now = Date.now()
                    const lastActionTimestamp = parseInt(lastUserClick)
                    // If user doesn't make click on website since 15 min, it should be disconnected
                    const oneHourAgo = now - (1000 * 60 * 15)
                    if(oneHourAgo > lastActionTimestamp){
                        logout()
                        window.location.replace("/")
                    }

                    return
                }

                console.log('refresh token')
                const refreshTokenResult = await refreshToken()
                console.log(refreshTokenResult)
            }
        }

        render() {
            if (this.state.loaded === true) {
                if (this.state.confirm != null) {
                // if (this.state.confirm != null || window.location.pathname.endsWith("emaillogin")) {
                    /* component that is currently being wrapper(App.js) */
                    //@ts-ignore
                    return <AuthComponent {...this.props} history={this.props.history} confirm={this.state.confirm} />
                } else {
                    // console.log("should redirect to login")
                    // console.log("this.state.confirm", this.state.confirm)
                    // console.log(this.state.confirm)
                    // console.log(window.location)
                    // console.log("withAuth props", this.props)
                    // if(this.props.token){
                        // return <Redirect to={`/login?token=${this.props.token}`} />                    
                    // }
                    
                    return <Redirect to="/login" />                    
                }
            } else {
                return null;
            }
        }
    };
}
