import { faChevronDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { getUsername, logout } from "../../api/Auth";
import i18n from "../../i18n";
import { AppEntity } from "../../models/AppEntity";
import classes from "./Header.module.css"
import FastpointImg from "../../assets/img/fastpoint.svg"

interface IProps {
    currentApp: AppEntity | undefined,
    onBackToApp: () => void,
    apps: AppEntity[]
}

const Header = (props: IProps) => {

    
    const [isProfileSubmenuDisplayed, setProfileSubmenuDisplayed] = useState<boolean>(false)
    
    
    return <div className={classes.HeaderContainer}>
        
        {props.currentApp 
            ? <img className={classes.HeaderAppLogo} src={props.currentApp.image} alt={props.currentApp.name}/> 
            : <img className={classes.HeaderAppLogo} src={FastpointImg} alt={"Fastpoint"}/>}
        

        <div className={classes.HeaderProfileContainer} onClick={() => { setProfileSubmenuDisplayed(!isProfileSubmenuDisplayed) }
                }>
                    <div className={classes.HeaderProfileContent}>
                        <FontAwesomeIcon size={"1x"} icon={faUser}/>
                        <label><FontAwesomeIcon icon={faChevronDown}/></label>
                        <label style={{display: "block"}}>{getUsername()}</label>
                    </div>

                </div>

                {isProfileSubmenuDisplayed === true ?
                    <>
                    <div className={classes.HeaderAppsContainer}>
                            <div className={classes.HeaderAppsContent}>
                                <label style={{display: "block"}}><Link to={"/apps"} onClick={() => {
                                    setProfileSubmenuDisplayed(false)
                                    props.onBackToApp()
                                }}>My apps</Link></label>
                            </div>
                    </div>
                    <div className={classes.HeaderSettingsContainer}>
                            <div className={classes.HeaderSettingsContent}>
                                <label style={{display: "block"}}><Link to={"/settings"} onClick={() => setProfileSubmenuDisplayed(false)}>Settings</Link></label>
                            </div>
                    </div>
                    <div className={classes.HeaderLogoutContainer} onClick={() => {
                        if (window.confirm(i18n.t("AreYouSureYouWantToLogout"))) {
                            logout()
                            window.location.replace("/")
                        }
                    }}>
                            <div className={classes.HeaderLogoutContent}>
                                <label style={{display: "block"}}><Trans>Logout</Trans></label>
                            </div>
                    </div>
                    </> : undefined }
                
    </div>
}

export default Header