//@ts-ignore
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

const instance = i18next.createInstance()


instance
    .use(initReactI18next)
    .use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: {
                AreYouSureYouWantToLogout: "Are you sure you want to log out ?",

                DownloadEnabledUpdated: "Download enabled",
                DownloadDisabledUpdated: "Download disabled",
                SyncNowUpdated: "Sync in progress ",
                DownloadAuthTokenSecretUpdated: "Download auth token secret updated",
                MakeAnalyticsNowUpdated: "Analytics started",
                ClientRemoveByNameUpdated: "Deleted client",
                AddClient: "Add client",
                BandUpdated: "Updated band",
                ChannelUpdated: "Updated channel",
                SsidUpdated: "Updated SSID",
                TxPowerUpdated: "Updated Tx power",
                RadioEnabledUpdated: "Enabled radio",
                RadioDisabledUpdated: "Disabled radio",
                WpaPasswordUpdated: "Updated WPA password",
                BssUtilizationUpdated: "Updated bss utilization",
                ObssUtilizationUpdated: "Updated obss utilization",
                IdleUpdated: "Updated idle channel",
                AllowedPortsUdpUpdated: "Updated allowed UDP ports",
                AllowedPortsTcpUpdated: "Updated allowed TCP ports",
                InternetBlockingEnabledUpdated: "Internet blocking enabled",
                InternetBlockingDisabledUpdated: "Internet blocking disabled",
                AutoCaptivePortalEnabledUpdated: "Automatic captive portal enabled",
                AutoCaptivePortalDisabledUpdated: "Automatic captive portal disabled",
                InternetWhitelistUpdated: "Updated internet whitelist",
                WelcomepageZipUrlUpdated: "Updated welcomepage zip url",
                MediaspotNameUpdated: "Mediaspot name updated",
                MediacenterHostUpdated: "Mediacenter host updated",
                Tr069HostUpdated: "TR069 host updated",
                WebsocketHostUpdated: "Websocket server host updated",
                FirmwareUpdateUrlUpdated: "Firmware update request OK",
                MediaspotCustomInfoUpdated: "Custom JSON updated",

                DownloadEnabledRequest: "Download activation",
                DownloadDisabledRequest: "Disable download",
                SyncNowRequest: "Synchronization request",
                DownloadAuthTokenSecretRequest: "Download auth token secret request",
                MakeAnalyticsNowRequest: "Analytics",
                ClientRemoveByNameRequest: " Client deletion",
                AddClientRequest: " Adding a client",
                BandRequest: "Updating Wi-Fi band",
                ChannelRequest: "Updating Wi-Fi channel",
                SsidRequest: "Updating SSID",
                TxPowerRequest: "Updating Wi-Fi transmit power",
                RadioEnabledRequest: "Radio activation",
                RadioDisabledRequest: "Radio deactivation",
                WpaPasswordRequest: "Updating WPA password",
                BssUtilizationRequest: "Updating bss utilization",
                ObssUtilizationRequest: "Updating obss utilization",
                IdleRequest: "Updating idle",
                AllowedPortsUdpRequest: "Updating allowed UDP ports",
                AllowedPortsTcpRequest: "Updating allowed TCP ports",
                InternetBlockingEnabledRequest: "Enabling internet blocking",
                InternetBlockingDisabledRequest: "Disabling internet blocking",
                AutoCaptivePortalEnabledRequest: "Enable automatic captive portal",
                AutoCaptivePortalDisabledRequest: "Disable automatic captive portal",
                InternetWhitelistRequest: "Updating internet whitelist",
                WelcomepageZipUrlRequest: "Updating welcome page zip url",
                MediaspotNameRequest: "Updating mediaspot name",
                MediacenterHostRequest: "Updating mediacenter host",
                Tr069HostRequest: "Updating TR069 host",
                WebsocketHostRequest: "Updating websocket server host",
                FirmwareUpdateUrlRequest: "Updating firmware update url",
                MediaspotCustomInfoRequest: "Updating mediaspot custom JSON",
            }
        },
        fr: {
            translations: {
                AreYouSureYouWantToLogout: "Êtes-vous sûre de vouloir vous déconnecter ?",

                DownloadEnabledUpdated: "Téléchargement activé",
                DownloadDisabledUpdated: "Téléchargement désactivé",
                SyncNowUpdated: "Synchronisation en cours",
                DownloadAuthTokenSecretUpdated: "Secret d'authentification basé sur des tokens à jour",
                MakeAnalyticsNowUpdated: "Analytiques démarrés",
                ClientRemoveByNameUpdated: "Client supprimé",
                AddClient: "Client ajouté",
                BandUpdated: "Bande à jour",
                ChannelUpdated: "Canal à jour",
                SsidUpdated: "SSID à jour",
                TxPowerUpdated: "Puissance de transmission à jour",
                RadioEnabledUpdated: "Radio activée",
                RadioDisabledUpdated: "Radio désactivée",
                WpaPasswordUpdated: "Clé WPA à jour",
                BssUtilizationUpdated: "Utilisation du canal bss à jour",
                ObssUtilizationUpdated: "Utilisation du canal obss à jour",
                IdleUpdated: "Canal inactif mis à jour",
                AllowedPortsUdpUpdated: "Ports UDP sortants autorisés à jour",
                AllowedPortsTcpUpdated: "Ports TCP sortants autorisés à jour",
                InternetBlockingEnabledUpdated: "Blocage d'internet activé",
                InternetBlockingDisabledUpdated: "Blocage d'internet désactivé",
                AutoCaptivePortalEnabledUpdated: "Portail captif automatique activé",
                AutoCaptivePortalDisabledUpdated: "Portail captif automatique désactivé",
                InternetWhitelistUpdated: "Whitelist internet mis à jour",
                WelcomepageZipUrlUpdated: "URL du zip de la page de bienvenue à jour",
                MediaspotNameUpdated: "Nom du mediaspot à jour",
                MediacenterHostUpdated: "Nom d'hôte du mediacenter à jour",
                Tr069HostUpdated: "Nom d'hôte du TR069 mis à jour",
                WebsocketHostUpdated: "Nom d'hôte du serveur websocket mis à jour",
                FirmwareUpdateUrlUpdated: "Demande de mis à jour du firmware OK",
                MediaspotCustomInfoUpdated: "JSON personnalisé à jour",

                DownloadEnabledRequest: "Activation du téléchargement",
                DownloadDisabledRequest: "Désactivation du téléchargement",
                SyncNowRequest: "Demande de synchronisation",
                DownloadAuthTokenSecretRequest: "Mise à jour du secret d'authentification basé sur des tokens",
                MakeAnalyticsNowRequest: "Mise à jour des analytiques",
                ClientRemoveByNameRequest: "Suppression du client",
                AddClientRequest: "Ajout du client",
                BandRequest: "Mise à jour de la bande Wi-Fi",
                ChannelRequest: "Mise à jour du canal Wi-Fi",
                SsidRequest: "Mise à jour du SSID",
                TxPowerRequest: "Mise à jour de la puissance de transmission",
                RadioEnabledRequest: "Activation radio",
                RadioDisabledRequest: "Désactivation radio",
                WpaPasswordRequest: "Mise à jour de la clé WPA",
                BssUtilizationRequest: "Mise à jour de l'utilisation du canal bss",
                ObssUtilizationRequest: "Mise à jour de l'utilisation du canal obss",
                IdleRequest: "Mise à jour du canal inactif",
                AllowedPortsUdpRequest: "Mise à jour des ports UDP sortants autorisés",
                AllowedPortsTcpRequest: "Mise à jour des ports TCP sortants autorisés",
                InternetBlockingEnabledRequest: "Activation du blocage d'internet",
                InternetBlockingDisabledRequest: "Désactivation du blocage d'internet",
                AutoCaptivePortalEnabledRequest: "Activation du portail captif automatique",
                AutoCaptivePortalDisabledRequest: "Désactivation du portail captif automatique",
                InternetWhitelistRequest: "Mise à jour de la whitelist internet",
                WelcomepageZipUrlRequest: "Mise à jour de l'URL du zip de la page de bienvenue",
                MediaspotNameRequest: "Mise à jour du nom du mediaspot",
                MediacenterHostRequest: "Mise à jour du nom d'hôte du mediacenter",
                Tr069HostRequest: "Mise à jour du nom d'hôte du TR069",
                WebsocketHostRequest: "Mise à jour du nom d'hôte du serveur websocket",
                FirmwareUpdateUrlRequest: "Demande de mis à jour du firmware",
                MediaspotCustomInfoRequest: "Mise à jour du JSON personnalisé"
            }
        }
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

// export default i18n;
export default instance
