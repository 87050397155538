import { Link } from "react-router-dom";
import { AppEntity } from "../../models/AppEntity";
import classes from "./AppComponent.module.css"

interface IProps {
    app: AppEntity,
    onSelectApp: (app: AppEntity) => void

}

const AppComponent = (props: IProps) => {

    return (
        <Link to={props.app.path} className={classes.AppComponentItemContainer} onClick={() => props.onSelectApp(props.app)}>
            <div className={classes.AppComponentItemContent}>
                <img src={props.app.image} alt={props.app.name}/>
            </div>
        </Link>
    )
}

export default AppComponent