import { addContentProvider, getContentProviders, refreshDevice} from "@fastpoint/mediaspot-management"
import { BaseSyntheticEvent, ChangeEvent, FormEvent, useEffect, useState } from "react"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import classes from "./CreateContentProviderModal.module.css"
import { ContentProviderEntity } from "@fastpoint/mediaspot-management/dist/entities/ContentProviderEntity"
import { AWSRegion } from "../../../models/AWSRegion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

interface IProps {
    onBackClicked: () => void
    onContentProviderCreated: () => void
}

const CreateContentProviderModal = (props: IProps) => {

    const [isCreateContentRequestInProgress, setCreateContentRequestInProgress] = useState<boolean>(false)
    
    const [inputBucketName, setInputBucketName] = useState<string>("")
    const [inputName, setInputName] = useState<string>("")
    const [inputTechnicalName, setInputTechnicalName] = useState<string>("")
    const [inputDescription, setInputDescription] = useState<string>("")
    const [inputUnzip, setInputUnzip] = useState<Unzip>("no")
    const [inputRegion, setInputRegion] = useState<keyof typeof AWSRegion | undefined>("eu-west-3")

    const isValidForm = () => {
        // Check that name, technical name, description and bucket name are set
        if(!inputName || !inputTechnicalName || !inputDescription || !inputBucketName){
            return false
        }
        
        // Check that region is valid
        if(!inputRegion || AWSRegion[inputRegion] === undefined){
            return false
        }

        if(inputUnzip !== "flatten_hierarchy" && inputUnzip !== "keep_hierarchy" && inputUnzip !== "no"){
            return false
        }

        return true
    }
    
    const createContentProvider = async() => {
        if(isValidForm()){
            
            console.log(inputName)
            console.log(inputDescription)
            console.log(inputTechnicalName)
            console.log(inputBucketName)
            console.log(inputRegion)
            console.log(inputUnzip)
            
            // setCreateContentRequestInProgress(true)
            // const contentProvidersResponse = await createContentProvider(props.deviceId, selectedContentProvider)
            
            // setCreateContentRequestInProgress(false)
            
            // if(!contentProvidersResponse.status){
            //     alert("An error occurred, unable to create content provider")
            //     return
            // }
            
            // props.onContentProviderCreated()

        }else {
            alert("Invalid values")
        }
    }


    const onCreateContentProviderClicked = async(e: FormEvent) => {
        e.preventDefault()
        await createContentProvider()
    }


    return <div className={classes.CreateContentProviderContainer}>
        <div className={classes.CreateContentProviderContent}>
            <label className={classes.CreateContentProviderTitleLabel}>Create content provider</label>
            <hr/>
            <form className={classes.CreateContentProviderForm} onSubmit={onCreateContentProviderClicked}>

                <div>
                    <label className={classes.CreateContentProviderFormLabel}>Name</label>
                    <input className={classes.CreateContentProviderFormInput} onChange={(e) => setInputName(e.target.value)}  type="text" placeholder="Name" value={inputName}/>
                </div>

                <div>
                    <label className={classes.CreateContentProviderFormLabel}>Description</label>
                    <input className={classes.CreateContentProviderFormInput} onChange={(e) => setInputDescription(e.target.value)}  type="text" placeholder="Description" value={inputDescription}/>
                </div>

                <div>
                    <label className={classes.CreateContentProviderFormLabel}>Technical name (client name)</label>
                    <input className={classes.CreateContentProviderFormInput} onChange={(e) => setInputTechnicalName(e.target.value)}  type="text" placeholder="Technical name" value={inputTechnicalName}/>
                    <label><FontAwesomeIcon icon={faInfoCircle} size="sm"/> The client name is used in content download URLs from mediaspots.</label>
                </div>

                <div>
                    <label className={classes.CreateContentProviderFormLabel}>AWS bucket name</label>
                    <input className={classes.CreateContentProviderFormInput} onChange={(e) => setInputBucketName(e.target.value)} type="text" placeholder="AWS Bucket name" value={inputBucketName}/>
                </div>



                
                <div>

                <label className={classes.CreateContentProviderFormLabel}>Region</label>
                    <select defaultValue={inputRegion}  className={classes.CreateContentProviderFormInput} onChange={(e:ChangeEvent<HTMLSelectElement>) => setInputRegion(e.target.value as keyof typeof AWSRegion)}>
                        {
                            Object.keys(AWSRegion).filter(it => isNaN(Number(it))).map(region => {
                                return <option key={region} value={region}>{region}</option>
                            })
                        }
                    </select>
                </div>
                
                <div>
                    <label className={classes.CreateContentProviderFormLabel}>Unzip</label>
                    <select defaultValue={"no"} onChange={(e) => setInputUnzip(e.target.value as Unzip)} className={classes.CreateContentProviderFormInput}>
                        <option key="no" value="no">No</option>
                        <option key="flatten_hierarchy" value="flatten_hierarchy">Flatten hierarchy</option>
                        <option key="keep_hierarchy" value="keep_hierarchy">Keep hierachy</option>
                    </select>
                    <label><FontAwesomeIcon icon={faInfoCircle} size="sm"/>  Select “Keep Hierarchy” if ZIP files should be unzipped in-situ (into .unzipped folder) on the mediaspot, maintaining the directory hierarchy inside the ZIP, or “Flatten Hierarchy” if the hierarchy should be flattened.<br />Note this requires additional storage space on the mediaspot. <br />Default “No” does not unzip ZIP files.</label>
                </div>

                <br />

                <div className={classes.CreateContentProviderActionsButtonContainer}>
                    <button onClick={props.onBackClicked} className={classes.CreateContentProviderCancelButton}>Cancel</button>
                    <button disabled={isCreateContentRequestInProgress} type="submit" className={classes.CreateContentProviderAddButton}>{isCreateContentRequestInProgress ? <Loader type="Oval" color="#FFFFFF" height={15} width={100} />: 'Create'}</button>
                </div>
            </form>
        </div>
    </div>
}

export default CreateContentProviderModal