import {Component} from "react"
import classes from "./Menu.module.css"
import classesMenuItem from "./MenuItem.module.css"
import {Link} from "react-router-dom";

import {withRouter} from "react-router-dom"
import { isAdmin, isSuperAdmin } from "../../api/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHdd, faPhotoVideo, faTools } from "@fortawesome/free-solid-svg-icons";


class Menu extends Component<any, any> {

    menuItems = [
        {
            name:"Files",
            url: "/console/files",
            adminRequired: false,
            icon: faPhotoVideo,
            superAdminRequired: false,
        },
        {
            name:"Mediaspots",
            url: "/console/mediaspots",
            adminRequired: false,
            icon: faHdd,
            superAdminRequired: false,
        },
        {
            name:"Administration",
            url: "/console/admin/",
            adminRequired: true,
            icon: faTools,
            superAdminRequired: true,
            childs: [
                {
                    name: "All mediaspots",
                    url: "/console/admin/mediaspots",
                    adminRequired: false,
                    superAdminRequired: false,
                },
                {
                    name: "Content providers",
                    url: "/console/admin/content_providers",
                    adminRequired: false,
                    superAdminRequired: false,
                },
                {
                    name: "Users",
                    url: "/console/admin/users",
                    adminRequired: false,
                    superAdminRequired: false,
                },
            ]
        }
    ]

    
    render(){
        const isUserAdmin = isAdmin()
        const isUserSuperAdmin = isSuperAdmin()
        return (
            
            <div className={classes.MenuContainer}>

                {
                    this.menuItems.map(item => {
                        return (
                            ((item.adminRequired === true && isUserAdmin !== true) || (item.superAdminRequired === true && isUserSuperAdmin !== true)) ? null :  <div key={item.url}>
                                <div key={item.url} className={[classesMenuItem.MenuItemContainer,  window.location.pathname.startsWith(item.url) ?  classesMenuItem.MenuItemActive : undefined].join(' ')}>
                                    <Link to={item.url}><FontAwesomeIcon icon={item.icon}/>&nbsp;&nbsp;{item.name}</Link>
                                </div>
                                {
                                
                                    ((item.adminRequired === true && isUserAdmin !== true) || (item.superAdminRequired === true && isUserSuperAdmin !== true)) ? null : item.childs?.map(subItem => {
                                        return <div key={subItem.url} className={[classesMenuItem.SubmenuItemContainer, window.location.pathname.startsWith(subItem.url) ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                                        <Link to={subItem.url}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{subItem.name}</Link>
                                    </div>
                                    })            
                                }
                            </div>
                        )       
                    })
                }

                
                <label className={classes.MenuAppVersion}>
                    <span>Version {process.env.REACT_APP_VERSION}</span>
                    <br />
                    <br />
                    <span className={classes.MenuCopyright}>Tous droits réservés Fastpoint 2021</span>
                </label>
                
            </div>
        )
    }
}

export default withRouter(Menu);
