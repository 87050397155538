import React, { useEffect } from "react"

import {isTokenExpired} from "../../api/Auth";
// import {History} from 'history'
import { RouteComponentProps, withRouter } from "react-router";
import classes from "./EmailLogin.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassEnd } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    token: string,
    onLogin: (token: string, history: {push: Function}) => void
    onExpiredToken: (history: {push: Function}) => void
}

const EmailLogin = (props: IProps & RouteComponentProps) => {

    useEffect(() => {
        if(isTokenExpired(props.token)){
            // alert("Your link is expired. Please log in again.")
            setTimeout(() => {
                props.onExpiredToken(props.history)
            }, 300000)
            
        }else {
            props.onLogin(props.token, props.history)
        }       
    }, [])
    
    return (<div className={classes.EmailLoginContainer}>
        {isTokenExpired(props.token) 
            ? <label>
                <FontAwesomeIcon icon={faHourglassEnd} />
                <br />
                Your token is expired, you'll be redirect to login,
                <br />
                if you're not redirected, <a href="/login">click here</a> 
            </label>
            : <label>Valide connection, you'll be redirected to app</label>
        }
    </div>)
}

export default withRouter(EmailLogin)