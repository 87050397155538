import { AppEntity } from "../models/AppEntity";
import AppComponent from "./appcomponent/AppComponent";
import classes from "./AppsComponent.module.css"
import FastpointImg from "../assets/img/fastpoint.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    apps: AppEntity[],
    onSelectApp: (app: AppEntity) => void
}


const AppsComponent = (props: IProps) => {
    
    return (
        <div className={classes.AppsComponentContainer}>
            <div className={classes.AppsComponentContent}>
                <img className={classes.AppsComponentFastpointLogo} src={FastpointImg} alt="Fastpoint" />


                { props.apps.length === 0 ? <h1><FontAwesomeIcon icon={faLock}/> Votre compte ne vous permet d'accéder à aucune application, veuillez contacter l'administrateur</h1> : null }

                
                { props.apps.length > 0 ? 
                    <>
                        <h1>Veuillez choisir l'application à laquelle vous souhaitez accéder</h1>
                
                        {
                            props.apps.map(app => {
                                return (
                                    <div className={classes.AppsComponentAppItem} key={app.name}>
                                        <AppComponent onSelectApp={props.onSelectApp} app={app} />
                                    </div>
                                )
                            })
                        }
                    </>
                : null }
            </div>
        </div>
    )
}

export default AppsComponent