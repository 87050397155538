import './App.css';

//@ts-ignore
// import Nucleot from "@fastpoint/nucleot"
// const Nucleot = asyncComponent(() => {
//     //@ts-ignore
//     console.log(import("@fastpoint/nucleot"))
//     //@ts-ignore
//     import("@fastpoint/nucleot").then(module => module.default)
// })

//@ts-ignore
// import {Index as Vision} from "@fastpoint/vision"
import classes from "./App.module.css"
import { withAuth } from './hoc/withAuth';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import AppsComponent from "./appscomponent/AppsComponent"
import { AppEntity } from './models/AppEntity';
import NucleotImg from './assets/img/nucleot.svg';
import VisionImg from './assets/img/vision.svg';
import FastpointIconImg from './assets/img/fastpoint_icon.svg';
import Header from './navigation/header/Header';

//@ts-ignore
import i18n from './i18n';
import {I18nextProvider} from "react-i18next";
import Settings from './settings/Settings';
import { useState } from 'react';

import asyncComponent from './AsyncComponent';

//@ts-ignore
// import Vision from "@fastpoint/vision/dist/index"

const Vision = asyncComponent(() => import("@fastpoint/vision/dist/index"))

//@ts-ignore
const Nucleot = asyncComponent(() => import("@fastpoint/nucleot"))

// const Vision = asyncComponent(() => {
//     //@ts-ignore
//     import("@fastpoint/vision/dist/index").then(module => module.default)
// })


import { getApps, getJWTTokenObject, isSuperAdmin } from './api/Auth';
import Unauthorized from './auth/unauthorized/Unauthorized';
// import MediaspotsList from './mediaspots/MediaspotsList';
import SyncserverHome from './syncserver/home/SyncserverHome';

// import { ToastContainer } from 'react-toastify';
import {ToastProvider} from "react-toast-notifications"

interface IProps {
    token: string|null
}

const NucleotApp = {
    name: "nucleot",
    path: "/nucleot",
    image: NucleotImg
}

const VisionApp = {
    name: "vision",
    path: "/vision",
    image: VisionImg,
}

const MediaspotManagementApp = {
    name: "mediaspots",
    path: "/console",
    image: FastpointIconImg,
}

const App = (props: IProps) => {
    
    let apps: AppEntity[] = [
        NucleotApp,
        VisionApp
    ]

    if(getJWTTokenObject()?.user.fpAdmin === true){
        apps.push(MediaspotManagementApp)
    }

    const [currentApp, setCurrentApp] = useState<AppEntity|undefined>(
        window.location.pathname.startsWith(NucleotApp.path) ? NucleotApp :
        window.location.pathname.startsWith(VisionApp.path) ? VisionApp : 
        window.location.pathname.startsWith(MediaspotManagementApp.path) ? MediaspotManagementApp :
        undefined
    )

    return (
        <>
        {/* Get current selected app */}
            <I18nextProvider i18n={i18n}>
                <Header apps={apps} currentApp={currentApp} onBackToApp={() => setCurrentApp(undefined)}/>
                <div className={classes.AppContainer}>
                    {/* <ToastContainer> */}
                    <ToastProvider>
                        <Switch>
                            <Route path="/apps/" render={() => <AppsComponent apps={apps} onSelectApp={(app) => setCurrentApp(app)}/>} />
                            <Route path="/settings" render={() => <Settings/>} />

                            <Route path="/nucleot/" render={() => apps.filter(it => it.name === "nucleot").length !== 0 ? <Nucleot/> : <Unauthorized/>} />
                            <Route path="/vision/" render={() => apps.filter(it => it.name === "vision").length !== 0 ? <Vision/> : <Unauthorized/>} /> 

                            {/* <Route path="/mediaspots/" render={() => apps.filter(it => it.name === "mediaspots").length !== 0 ? <MediaspotsList/> : <Unauthorized/>} />  */}
                            <Route path="/console/" render={() => isSuperAdmin() ? <SyncserverHome/> : <Unauthorized/>} /> 
                            
                            {apps.length === 1 ? <Redirect exact from={"/"} to={apps[0].path} /> : <Redirect exact from={"/"} to={"/apps"} />}
                            
                        </Switch>
                    </ToastProvider>
                </div>
            </I18nextProvider>
            </>
        
    )
}

export default withAuth(App)