import { useEffect, useState } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { getToken } from "./api/Auth"
import App from "./App"
import Login from "./auth/login/Login"
import EmailLogin from "./auth/login/EmailLogin"

import {setToken as storeToken} from "./api/Auth"
import { Config } from "@fastpoint/mediaspot-management"

const Router = () => {
    
    const [token, setToken] = useState<string|null>(getToken())

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" render={(props) => <Login {...props} token={token} onLogin={(loginTokenResult: string, history: {push: Function}) => {
                    setToken(loginTokenResult)
                    history.push("/")
                }} />} />

                <Route exact path="/emaillogin" render={(props) => <EmailLogin {...props} token={props.location.search.split("=")[1]} onExpiredToken={(history) => {
                    history.push("/")                
                }} onLogin={(loginTokenResult: string, history: {push: Function}) => {
                    Config.getInstance().updateToken(loginTokenResult)
                    setToken(loginTokenResult)
                    storeToken(loginTokenResult)
                    
                    history.push("/")
                }} /> }/>
                
                <App token={token}/>
            </Switch>
        </BrowserRouter>
    )
}

export default Router