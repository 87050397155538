import React, {ChangeEvent, Component, FormEvent} from 'react';
import classes from './Login.module.css'
import {isTokenExpired, login} from '../../api/Auth'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
//import {getI18n, Trans} from "react-i18next";

import SentMailSuccess from "../../assets/img/sent_mail_success.svg"
import AppIcon from "../../assets/img/fastpoint.svg"
import { Redirect } from 'react-router';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// import {History} from 'history'

interface IProps {
    onLogin: (token: string, history: {push: Function}) => void
    token: string|null
}

interface IState {
    username: string,
    password: string,
    error: object|undefined,

    // isJwtTokenLoaded: boolean,
    requestInProgress: boolean,

    isSentEmail: boolean
}

class Login extends Component<IProps & RouteComponentProps, IState> {

    constructor(props: IProps & RouteComponentProps){
        super(props)
        console.log(this)
        this.state = {
            username: "",
            password: "",
            error: undefined,

            isSentEmail: false,
            requestInProgress: false,
        }

        if(!isTokenExpired(this.props.token)){
            window.location.replace("/")
        }
    }

    componentDidMount(){
        console.log(process.env)
        console.log(this.props)
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit = async(event: FormEvent) => {
        event.preventDefault()
        this.setState({requestInProgress: true}, async() => {
            const emailLoginResult = await login(this.state.username, this.state.password)
            this.setState({requestInProgress: false}, async() => {
                if(!emailLoginResult.success){
                    this.setState({ error : emailLoginResult.error })
                }else {
                    this.setState({
                        isSentEmail : true,
                        error: undefined
                    })
                    // this.props.onLogin(loginResult.response!!.token, this.props.history)
                }
            });
        })
    }

    handleUsernameChanged = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({username: event.target.value})
    }

    handlePasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({password: event.target.value})
    }


    render() {
        // Display in case of loading and during redirection
        const validationSection = (this.state.requestInProgress || this.state.isSentEmail) ?
            (<div className={classes.LoginSpinnerContainer}>
                <div className={classes.LoginSpinnerContent}>
                    <Loader
                        type="Oval"
                        color="#FFFFFF"
                        height={30}
                        width={100}
                    />

                </div>
            </div>)
            :
            <input className={classes.LoginValidateButton} type={"submit"} value={"RECEVOIR UN LIEN DE CONNEXION"}/>

        return (
            <div className={classes.LoginContainer}>
                <div style={{textAlign: "center", marginBottom: "20px", height: "80px"}}>
                    <img src={AppIcon} alt={"App icon"} style={{height: "100%"}}/>
                </div>


                {
                    this.state.isSentEmail 

                    ? <div className={classes.SentMailSuccessContainer}>
                        <img src={SentMailSuccess} alt="success login"/>
                        
                        <p>Mail envoyé.
                            <br />
                            Veuillez cliquer sur le lien présent dans le mail pour être automatiquement connecté.
                        </p>
                        <button className={classes.LoginBackToLoginButton} onClick={() => {
                            this.setState({
                                isSentEmail: false
                            })
                        }}>← Retour à la page de connexion</button>
                    </div> 

                    : <>
                        <form onSubmit={this.handleSubmit} className={classes.LoginForm}>
                            <label>EMAIL</label>
                            <input type="email" onChange={this.handleUsernameChanged} required/>

                            {this.state.error != null ? <p style={{color:"red"}}>{this.state.error}</p> : null}

                            {validationSection}
                        </form>
                        <label style={{textAlign: "center", marginTop: "10px"}}>L'accès à ce service est strictement réservé aux utilisateurs authentifiés et disposants d'un accès valide.</label>
                        <br />
                        <label style={{textAlign: "center"}}>Tous droits réservés Fastpoint {new Date(Date.now()).getFullYear()}</label>
                    </>
                    }

                
            </div>
        );
    }
}

export default withRouter(Login)
