import { Switch, Route, Redirect } from "react-router"
import ContentProviders from "../contentproviders/ContentProviders"
import MediaspotsList from "../mediaspots/MediaspotsList"
import Menu from "../menu/Menu"

const SyncserverHome = () => {

    return (
        <>
            <Menu/>
            <Switch>
                <Route path="/console/admin/mediaspots" render={() => <MediaspotsList/>} />
                <Route path="/console/admin/content_providers" render={() => <ContentProviders/>} />
                <Redirect exact from="/console" to="/console/admin/mediaspots"/>
            </Switch>
        </>
    )
}

export default SyncserverHome