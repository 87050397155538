import React, { ChangeEvent, useEffect, useState } from "react"
import {ContentProviders, getMediaspot, getMediaspotsSummary, getMediaspotTasks, TasksManager, WebsocketSubscriptionType} from "@fastpoint/mediaspot-management"
       
import { Mediaspot, MediaspotResponse } from "@fastpoint/mediaspot-management/dist/entities/Mediaspot"
import classes from "./MediaspotsList.module.css"
import { MediaspotDetails } from "./mediaspotdetails/MediaspotDetails"
import { Task } from "@fastpoint/mediaspot-management/dist/entities/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRedo, faSearch, faSync } from "@fortawesome/free-solid-svg-icons"
import AddContentProviderModal from "../contentproviders/AddContentProviderModal/AddContentProviderModal"
import Loader from "react-loader-spinner"
import { timeSince } from "../../helpers/dateHelper"

interface IProps {
    
}

type SortColumn = "name" | "id" | "cp" | "last_contact" | "last_update"
enum SortType { 
    asc,
    desc
}

const MediaspotsList = (props: IProps) => {

    // "" empty string is used to get all content providers mediaspots
    
    console.log(props)
    
    
    const [selectedContentProvider, setSelectedContentProvider] = useState<string>("")
    const [errorLoading, setErrorLoading] = useState<boolean>(false)
    const [contentProviders, setContentProviders] = useState<string[]>([])
    const [mediaspots, setMediaspots] = useState<Array<MediaspotResponse>>([])
    const [selectedMediaspotId, setSelectedMediaspotId] = useState<string|undefined>(undefined)
    const [isLoading, setLoading] = useState<boolean>(true)
    const [searchText, setSearchText] = useState<string|undefined>(undefined)
  
    const [sortColumn, setSortColumn] = useState<SortColumn>("name")
    const [sortType, setSortType] = useState<SortType>(SortType.asc)

    const [isAddingContentProvider, setAddingContentProvider] = useState<boolean>(false)

  
    const loadMediaspots = async() => {
        setLoading(true)
        const mediaspotsResponse =  await getMediaspotsSummary(selectedContentProvider)
        
        if(!mediaspotsResponse.status){
            alert("error during mediaspots loading")
            setErrorLoading(true)
            setLoading(false)
            return
        }
        
        const mediaspots = (mediaspotsResponse.result as Array<MediaspotResponse>)
        setContentProviders(mediaspots.flatMap(mediaspot => mediaspot.cp.map(cp => cp.name)).filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i).filter(it => it))
        setMediaspots(mediaspots)
        setLoading(false)

        console.log(mediaspots)
    }

    const onSelectMediaspot = async(mediaspotId: string) => {
        setSelectedMediaspotId(mediaspotId)
        console.log(TasksManager.getInstance().getTasks(mediaspotId))
    }

    
    useEffect(() => {
        loadMediaspots()
    }, [])

    
    const noMediaspot = <tr>
        <td colSpan={5}>No mediaspot</td>
    </tr>

    const errorLoadingRetryButton = <tr>
        <td colSpan={5}><button className={classes.MediaspotsListRetryErrorButton}  onClick={() => loadMediaspots()}><FontAwesomeIcon icon={faRedo} size="sm" /> Retry</button></td>
    </tr>

    const devicesRows = mediaspots.sort((ms1, ms2) => { // Sort mediaspot by selected sort column
        switch(sortColumn){
            case "id": return (ms1.id.toLowerCase() < ms2.id.toLowerCase()) ? (sortType === SortType.asc ? -1 : 1) : (sortType === SortType.desc ? -1 : 1)
            case "name": return (ms1.name.toLowerCase() < ms2.name.toLowerCase()) ? (sortType === SortType.asc ? -1 : 1) : (sortType === SortType.desc ? -1 : 1)
            case "cp": return (ms1.cp.map(it => it.name).join("").toLowerCase() < ms2.cp.map(it => it.name).join("").toLowerCase()) ? (sortType === SortType.asc ? -1 : 1) : (sortType === SortType.desc ? -1 : 1)
            case "last_contact": return (ms1.lastContactReceived < ms2.lastContactReceived) ? (sortType === SortType.asc ? -1 : 1) : (sortType === SortType.desc ? -1 : 1)
            case "last_update": return ((ms1.informationLastUpdate && ms2.informationLastUpdate) ? (ms1.informationLastUpdate < ms2.informationLastUpdate): undefined) ? (sortType === SortType.asc ? -1 : 1) : (sortType === SortType.desc ? -1 : 1)
            default: return 0   
        }
    }).filter(it => { // Filter with search string on id, name and content providers, and selected content provider
        if(selectedContentProvider && !it.cp.map(cp => cp.name).includes(selectedContentProvider)){
            return false
        }

        if(!searchText){
            return true
        }
        console.log("filter cp ", selectedContentProvider, it.cp)
        return it.id.toLowerCase().includes(searchText.toLowerCase()) || it.name.toLowerCase().includes(searchText.toLowerCase()) || it.cp.map(cp => cp.name.toLowerCase()).join(", ").includes(searchText.toLowerCase())
    }).map(mediaspot => {
        let onlineCircleClass = classes.MediaspotOfflineCircle
        const lastContact = new Date(mediaspot.lastContactReceived).getTime() * 1000
        if(lastContact > (Date.now() - 1000 * 60 * 10)){
            onlineCircleClass = classes.MediaspotNotOfflineYetCircle
        }

        if(lastContact > (Date.now() - 1000 * 60 * 6)){
            onlineCircleClass = classes.MediaspotOnlineCircle
        }

        return (
            <tr key={mediaspot.id} className={selectedMediaspotId === mediaspot.id ? classes.MediaspotSelectedRow : undefined} onClick={() => onSelectMediaspot(mediaspot.id)}>
                <td>{mediaspot.name}</td>
                <td>{mediaspot.id}</td>
                <td>{mediaspot.cp.map(cp => cp.name)}</td>
                <td>
                    <div className={onlineCircleClass}/>
                    <label className={classes.LastContactLabel}>
                        {`${timeSince(mediaspot.lastContactReceived * 1000)} (${new Date(new Date(mediaspot.lastContactReceived).getTime() * 1000).toLocaleString()})`}  
                    </label>
                </td>
                <td>{mediaspot.informationLastUpdate ? `${timeSince(mediaspot.informationLastUpdate * 1000)} (${new Date(new Date(mediaspot.informationLastUpdate).getTime() * 1000).toLocaleString()})` : "--"}</td>
            </tr>
        )
    })

    const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
        if(!event.target.value){
            setSearchText(undefined)
        }
        setSearchText(event.target.value)
    }

    
    const onSortClick = (column: SortColumn) => {
        // If click on already selected column, switch sort type. Else put "asc" per default
        if(column === sortColumn){
            setSortType(sortType === SortType.asc  ? SortType.desc : SortType.asc)
        }else {
            setSortType(SortType.asc)
        }
        setSortColumn(column)
    }


    const onMediaspotUpdated = (mediaspot: Mediaspot) => {
        const mediaspotsCopy = [...mediaspots]
        const indexToUpdate = mediaspotsCopy.findIndex(it => it.id === mediaspot.id)
        if(indexToUpdate === -1){
            return
        }
        let mediaspotToUpdate = {...mediaspotsCopy[indexToUpdate]}

        mediaspotToUpdate.cp = mediaspot.cp
        mediaspotToUpdate.name = mediaspot.name
        
        mediaspotToUpdate.lastContactReceived = mediaspot.lastContactReceived.getTime() / 1000 
        if(mediaspot.informationLastUpdate){
            mediaspotToUpdate.informationLastUpdate = mediaspot.informationLastUpdate.getTime() / 1000
        }

        mediaspotsCopy[indexToUpdate] = mediaspotToUpdate
        setMediaspots(mediaspotsCopy)
    }

    
    const sortClass = sortType === SortType.asc ? classes.AscSortColumn : classes.DescSortColumn

    return (
        <>
        
            <div className={[classes.MediaspotsListContainer, selectedMediaspotId !== undefined ? classes.MediaspotsListContainerCollapsed : undefined].join(" ")}>

                <div className={[classes.MediaspotsListContent, selectedMediaspotId !== undefined ? classes.MediaspotsListContentCollapsed : undefined].join(" ")}>
                    <h3>Gestion des mediaspots</h3>
                    <div className={classes.MediaspotsSearchContainer}>
                        <label htmlFor="search_mediaspot_input">Search <FontAwesomeIcon icon={faSearch}/></label>
                        <input id="search_mediaspot_input" type="text" onChange={onSearchTextChanged} />
                    </div>
                    <select className={classes.MediaspotsListCpsSelect} defaultValue={selectedContentProvider} onChange={(event) => {setSelectedContentProvider(event.target.value)}}>
                        <option key={"all_mediaspot"} value="">Tous les mediaspots</option>
                        {
                            contentProviders.map(it => {
                                return <option key={it} value={it}>{it}</option>
                            })
                        }
                    </select>
                    <button className={classes.MediaspotsListRefreshButton} onClick={() => loadMediaspots()}><FontAwesomeIcon icon={faSync} size="sm"/> Refresh list</button>
                    <hr />
                    <table className={classes.MediaspotsListTable}>
                        <thead>
                            <tr>
                                <th onClick={() => onSortClick("name")} className={sortColumn === "name" ? [classes.SortColumn, sortClass].join(" ") : undefined}>Name</th>
                                <th onClick={() => onSortClick("id")} className={sortColumn === "id" ? [classes.SortColumn, sortClass].join(" ") : undefined}>Id</th>
                                <th onClick={() => onSortClick("cp")} className={sortColumn === "cp" ? [classes.SortColumn, sortClass].join(" ") : undefined}>Content Providers</th>
                                <th onClick={() => onSortClick("last_contact")} className={sortColumn === "last_contact" ? [classes.SortColumn, sortClass].join(" ") : undefined}>Last contact received</th>
                                <th onClick={() => onSortClick("last_update")} className={sortColumn === "last_update" ? [classes.SortColumn, sortClass].join(" ") : undefined}>Information last updated</th>
                            </tr>
                        </thead>
                        <tbody>


                            {/* If loading, display loader, else handle devices */}
                            {isLoading ? <tr><td colSpan={5}><Loader type="Oval" width={30} height={30} color="#2F9BD9"/></td></tr> :
                                devicesRows.length > 0 ? devicesRows : (errorLoading ? errorLoadingRetryButton : noMediaspot)
                            }
                            
                            
                        </tbody>
                    </table>
                
                </div>
            
                {
                    selectedMediaspotId ? (
                        <>
                            {
                                isAddingContentProvider ? 
                                    <AddContentProviderModal 
                                        onBackClicked={() => setAddingContentProvider(false)}
                                        onContentProviderAdded={() => setAddingContentProvider(false)}
                                        deviceId={selectedMediaspotId}/> 
                                : null 
                            }
                            <div className={classes.MediaspotDetailsContainer}>
                                <MediaspotDetails onMediaspotUpdate={onMediaspotUpdated} onAddContentProviderClicked={(deviceId) => {setAddingContentProvider(true)}} mediaspotId={selectedMediaspotId} />                                
                            </div>
                        </>
                        
                    ) : undefined
                }
            </div>
        </>  
    )
}



export default MediaspotsList