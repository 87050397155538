import { faLock, faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import classes from "./Unauthorized.module.css"

const Unauthorized = () => {
    return (
        <div className={classes.UnauthorizedContainer}>
            <FontAwesomeIcon icon={faLock}/>
            <br />
            <label>Accès non autorisé à cette application</label>
            <br />
            <Link to="/apps" className={classes.UnauthorizedBackToAppsButton}><FontAwesomeIcon icon={faLongArrowAltLeft}/> Retour aux applications</Link>
        </div>
    )
}

export default Unauthorized