import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import { Config } from '@fastpoint/mediaspot-management';
import { getToken } from './api/Auth';


Config.getInstance().update({
    API_PROTOCOL: "https",
    API_HOST: `3p7zrbw8c4.execute-api.eu-west-3.amazonaws.com`,
    API_STAGE: "dev",
    API_PORT: 443,
    API_KEY: `fGas1WLAcQ1UvsfqT8UpYa4MdDj5kDlma1wu2LiG`,
    WEBSOCKET_HOST: "at5h355lo3.execute-api.eu-west-3.amazonaws.com",
    WEBSOCKET_PORT: "443",
    WEBSOCKET_STAGE: "dev",
})

const token = getToken()
if(token){
   Config.getInstance().updateToken(token)
}


ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
        <Router/>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
